import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

export default function SurveyResponsesPage() {
  const [error, setError] = useState(null);
  const [surveyResponses, setSurveyResponses] = useState([]);

  useEffect(() => {
    async function fetchSurveyResponses() {
      try {
        const { data } = await axios.get(
          "https://radon-api.herokuapp.com/api/v1/survey_responses"
        );
        setSurveyResponses(data);
      } catch (error) {
        console.error("Error fetching questionnaire responses:", error);
        setError("Error fetching data of surveys");
      }
    }

    fetchSurveyResponses();
  }, []);

  if (error) return <div>{error}</div>;

  return (
    <main>
      <table className="ui selectable table">
        <thead>
          <tr>
            <th>Respondent Name</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {surveyResponses.map(({ id, full_name, created_at }) => (
              <tr key={id}>
                <th>{full_name}</th>
                <th>
                  <Moment format="MMMM Do YYYY, h:mm:ss a">{created_at}</Moment>
                </th>
                <th>
                  <Link key={id} to={`/survey_responses/${id}`} className="ui primary button my-2">
                    Show
                  </Link>
                </th>
              </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
}
