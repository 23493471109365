import React, { Component } from 'react';
import '../App.css';
import axios from 'axios'
import 'semantic-ui/dist/semantic.min.css';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader, Card } from 'semantic-ui-react'
import Moment from 'react-moment';
import Linkify from 'react-linkify';

class News extends Component {

  constructor() {
    super();
    this.state = { is_loading: true,
                   news: null,
                   total_news: null,
                   limit: 50,
                   offSet:0,
                   loadMore: null,
                  };
    this.fetchMoreNews = this.fetchMoreNews.bind(this);
  }

  componentDidMount() {
    this.get_news();
  }

  get_news(){
    axios.get('https://radon-api.herokuapp.com/api/v1/news', { params: { limit: this.state.limit, offset: this.state.offSet }})
    .then(response => {
      if (response.data.length > 0) {
        this.setState({news: response.data,
                      total_news: response.data.length, loadMore: false});
        // if (response.data.news.pagination.count < this.state.limit) {
        //   this.setState({loadMore: false});
        // } else if (this.state.offSet + this.state.limit > response.data.news.pagination.count) {
        //   this.setState({limit: this.state.offSet + this.state.limit - response.data.news.pagination.count,
        //                  loadMore: true})
        // }  else {
        //   this.setState({loadMore: true, offSet: this.state.offSet + this.state.limit, limit: this.state.limit})
        // }
      }
    })
    .catch(error => {
      console.log(error)
      ToastsStore.info("Oops. Looks like something went wrong!");
    })
  }

  fetchMoreNews(){
    if (this.state.loadMore === false) return;

    axios.get('https://radon-api.herokuapp.com/api/v1/news', { params: {limit: this.state.limit, offset: this.state.offSet}})
    .then(response => {
      if (response.data.news.pagination.count > 0) {
        let joined = this.state.news.concat(response.data.news.data);
        this.setState({news: joined,
                      total_news: response.data.news.pagination.count});
        console.log(this.state.limit);

        if (response.data.news.pagination.count < this.state.limit) {
          this.setState({loadMore: false})
        } else {
          this.setState({loadMore: true, offSet: this.state.offSet + this.state.limit})
        }
      }
    })
    .catch(error => {
      ToastsStore.info("Oops. Looks like something went wrong!");
    })
  }

  render() {
    if(!this.state.news) return  <Loader active size='big' inline='centered' className='margin-top-20' />

    return (
      <div>
        <center>
          <p className='gray-text font-3'>Recent news on radon</p>
        </center>
        <hr/>
        <InfiniteScroll
          dataLength={this.state.offSet + this.state.limit}
          next={this.fetchMoreNews}
          hasMore={this.state.loadMore}
          loader={<Loader active inline='centered' />}
          endMessage={
            <p style={{textAlign: 'center'}}>
              <b>You have seen it all!</b>
            </p>
          }
        >
          {this.state.news.map((item, index) => (
            item ? (
            <Card key={index} className='tweet-card'>
              <Card.Content>
                <Card.Header>{item.title}</Card.Header>
                {/* <Card.Meta><Moment format="MMMM DD, YYYY">{item.published_at}</Moment></Card.Meta> */}
                <Card.Description className='text-wrap'>
                  {/* {item.text.split(' ').slice(0,50).join(' ')}... */}
                  {item.title.split(' ').slice(0,50).join(' ')}...
                  <br/><br/>
                  <Linkify properties={{target: '_blank'}}>
                    <span className='small-font'>{item.link}</span>
                  </Linkify>
                </Card.Description>
              </Card.Content>
            </Card>
            ):("")
          ))}
        </InfiniteScroll>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER}/>
      </div>
    );
  }
}

export default News;
