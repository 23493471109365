import React, { Component } from 'react'
import axios from 'axios'
import 'semantic-ui/dist/semantic.min.css';
import Linkify from 'react-linkify';
import { Header } from 'semantic-ui-react'
import { List, Segment, Loader } from 'semantic-ui-react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'
import { Pagination } from 'semantic-ui-react'

class Stats extends Component {

  constructor(props) {
    super(props)
    this.state = {
      user: null,
      tweets_count: null,
      friends_count: null,
      followers_count: null,
      news: [],
      totalPages: 0,
      visits: [],
      does_the_radon: 0,
      radon_rough: 0,
      radon_education: 0
    }
    this.setTimer = false;
    this.initialize_user = this.initialize_user.bind(this);
    this.get_user_info = this.get_user_info.bind(this);
    this.getNewsStat = this.getNewsStat.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem('auth_token')){
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');
      this.initialize_user()
      this.getNewsStat(1);
      this.getPageVisits();
      this.getTweetsStats();
      this.get_user_info();
      if (this.setTimer == false || this.props.onTimer == false) {
        this.props.startTimer();
        this.setTimer = true;
      }
    }
  }

  initialize_user(){
    axios.get('https://radon-api.herokuapp.com/api/v1/get_current_user')
    .then(response => {
      this.setState({user: response.data.user})
    })
    .catch(error => {
      localStorage.removeItem('auth_token');
      window.location = '/';
    })
  }

  getNewsStat(page){
    axios.get('https://radon-api.herokuapp.com/api/v1/news/stats', { params: {page: page}})
    .then(response => {
      console.log(response);
      this.setState({news: response.data.result, totalPages: response.data.total_pages })
    })
    .catch(error => console.log(error))
  }

  handleActivePage = (e, { activePage }) => {
    this.getNewsStat(activePage)
  }

  getPageVisits(){
    axios.get('https://radon-api.herokuapp.com/api/v1/stats/page_visits')
    .then(response => {
      this.setState({ visits: response.data.result })
    })
    .catch(error => console.log(error))
  }

  getTweetsStats(){
    axios.get('https://radon-api.herokuapp.com/api/v1/stats/get_tweet_stats')
    .then(response => {
      this.setState({ does_the_radon: response.data.does_the_radon,
                      radon_rough: response.data.radon_rough,
                      radon_education: response.data.radon_education })
    })
    .catch(error => console.log(error))
  }

  getPaginationBar = () => {
    if(this.state.totalPages > 1){
      return <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='3'>
                  <Menu floated='right' pagination>
                  <Pagination
                    defaultActivePage={1}
                    totalPages={10}
                    totalPages={this.state.totalPages}
                    onPageChange={this.handleActivePage} />
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>

    }else{ return null }

  }


  get_user_info() {
    axios.get('https://radon-api.herokuapp.com/api/v1/stats')
    .then(response => {
      this.setState({tweets_count: response.data.tweets, friends_count: response.data.friends, followers_count: response.data.followers })
    })
    .catch(error => console.log(error))
  }


  render() {
    if(this.state.tweets_count == null){
      return  <Loader active size='big' inline='centered' className='margin-top-20' />
    }

    return(
      <div>
        <center>
          <Header as='h1'>Stats for @RadonInformer</Header>
          <Segment inverted style={{width: '20%'}}>
            <List divided inverted relaxed>
              <List.Item>
                <List.Content>
                  <List.Header className='f-1-5'>Tweets</List.Header>
                  <br/>
                  <div className='f-1'>{this.state.tweets_count}</div>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header className='f-1-5'>Followers</List.Header>
                  <br/>
                  <div className='f-1'>{this.state.followers_count}</div>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header className='f-1-5'>Follows</List.Header>
                  <br/>
                  <div className='f-1'>{this.state.friends_count}</div>
                </List.Content>
              </List.Item>
            </List>
          </Segment>

          <Header as='h1'>Hashtag Usage</Header>
          <Header as='h4' className='mt-neg-5'>This data is based on results over the last 15 days</Header>
          <Segment inverted style={{width: '20%'}}>
            <List divided inverted relaxed>
              <List.Item>
                <List.Content>
                  <List.Header className='f-1-5'>#UNDdoestheradontest</List.Header>
                  <br/>
                  <div className='f-1'>{this.state.does_the_radon}</div>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header className='f-1-5'>UNDradonroughriders</List.Header>
                  <br/>
                  <div className='f-1'>{this.state.radon_rough}</div>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header className='f-1-5'>UNDradoneducation</List.Header>
                  <br/>
                  <div className='f-1'>{this.state.radon_education}</div>
                </List.Content>
              </List.Item>
            </List>
          </Segment>

          <br/>
          <hr/>
          <Header as='h1'>News Link Stats</Header>
          <Table celled style={{width: '80%'}}>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell>URL</Table.HeaderCell>
                <Table.HeaderCell>Link Visit Count</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {this.state.news.map((item, index) => (
              <Table.Body key={index}>
                <Table.Row>
                  <Table.Cell width={12}><Linkify properties={{target: '_blank'}}>{item.url}</Linkify></Table.Cell>
                  <Table.Cell textAlign='center'>{item.count}</Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
            {this.getPaginationBar()}

          </Table>


          <Header as='h1'>Page Visit Stats</Header>
          <Table celled style={{width: '80%'}}>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell>URL</Table.HeaderCell>
                <Table.HeaderCell>Page Visits</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {this.state.visits.map((item, index) => (
              <Table.Body key={index}>
                <Table.Row>
                  <Table.Cell width={12}><Linkify properties={{target: '_blank'}}>{'https://radon-production.herokuapp.com' + item.path}</Linkify></Table.Cell>
                  <Table.Cell textAlign='center'>{item.count}</Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
          </Table>
          <br/><br/>



        </center>
      </div>
    );
  }
}

export default Stats
